import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { getEnvironment } from '$lib/services/get-environment';
import { Environment } from '$lib/enum/environment';

Sentry.init({
  enabled: getEnvironment() === Environment.production,
  dsn: 'https://af334637a7aa9cd5d5fdac896718490c@o968153.ingest.sentry.io/4505840508403712',
  tracesSampleRate: 0.05,
});

export const handleError = handleErrorWithSentry();
